
import { computed, defineComponent, reactive } from "vue";
import { useStore } from "vuex";
import { useHead } from "@vueuse/head";
import { useRoute, useRouter } from "vue-router";

import PDataTable from "primevue/datatable";
import PColumn from "primevue/column";
import PProgressBar from "primevue/progressbar";
import PPaginator from "primevue/paginator";
import CDropdown, { DropdownItem } from "@/components/Dropdown.vue";
import PBreadcrumb from "primevue/breadcrumb";

import { useFilter, usePagination, getUserName } from "@/libs/utils";
import { Procedure } from "@/store/procedure/types";

const VProcedureList = defineComponent({
  name: "VProcedureList",
  components: { PDataTable, PColumn, PProgressBar, PPaginator, CDropdown, PBreadcrumb },
  setup() {
    useHead({ title: "Lista de procedimentos | obmed" });

    const store = useStore();
    const route = useRoute();
    const router = useRouter();
    const { hasFilters } = useFilter();

    const procedures = computed(() => store.state.procedure.list);
    const loading = reactive({ list: false });

    function getDropdownOptions(procedure: Procedure) {
      const items: DropdownItem[] = [
        {
          label: "Visualizar",
          icon: "fas fa-info-circle",
          to: { name: "procedure-edit", params: { _id: String(procedure.id) } },
        },
        {
          label: "Editar",
          icon: "fas fa-edit",
          to: { name: "procedure-edit", params: { _id: String(procedure.id) } },
        },
        {
          label: "Remover",
          icon: "fas fa-trash-alt",
          to: { name: "procedure-delete", params: { _id: String(procedure.id) } },
        },
      ];

      return items;
    }
    const breadcrumb = [
      {
        label: "Procedimentos",
        icon: "fas fa-briefcase-medical mr-1",
        to: { name: "procedure-list", query: route.query },
      },
      {
        label: "CBHPM",
        icon: "fas fa-hospital-alt mr-1",
        to: { name: "procedure-list", query: route.query },
      },
    ];

    const { pagination, handleUpdateCurrentPage } = usePagination({
      module: "procedure",
      setPage: "setProcedurePaginationPage",
      updateList: getProcedures,
    });

    async function getProcedures() {
      loading.list = true;
      await store.dispatch("getProcedures");
      loading.list = false;
    }

    if (hasFilters.value) router.push({ name: "procedure-filter", query: route.query });
    else getProcedures();

    return {
      ...{ procedures, pagination, loading, breadcrumb, hasFilters },
      ...{ getUserName, getDropdownOptions, handleUpdateCurrentPage, getProcedures },
    };
  },
});

export default VProcedureList;
